import React, {Suspense, useEffect, useState} from "react";
import axios from "axios";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Loading from "./components/Loading/Loading";
import {useAppState} from "./state";
import Login, {getCsrfToken} from "./components/Login/Login";
import {SET_USER} from "./state/reducers/userReducer";
import PayreqNav from "./components/Nav/Nav";
import {Container} from "react-bootstrap";
import PayreqUsers from "./components/PayreqUsers/PayreqUsers";
import SpecificPayreqUser from "./components/SpecificPayreqUsers/SpecificPayreqUser";
import AccountDetails from "./components/AccountDetails/AccountDetails";
import Dashboard from "./components/Dashboard/Dashboard";
import UserEvents from "./components/UserEvents/UserEvents";
import ApprovalRequests from "./components/ApprovalRequests/ApprovalRequests";
import RequestDetails from "./components/ApprovalRequestDetails/RequestDetails";
import PayreqAccounts from "./components/Accounts/Accounts";
import MailerCredits from "./components/Credits/MailerCredits";
import AddContact from "./components/AccountDetails/SupportContacts/AddContact/AddContact";
import MailTemplatesDetails from "./components/AccountDetails/MailTemplates/Details/MailTemplatesDetails";
import Integrators from "./components/Integrators/Integrators";
import IntegratorDetails from "./components/IntegratorDetails/IntegratorDetails";
import ConfigLists from "./components/AccountDetails/AdvancedConfig/ConfigLists/ConfigLists";
import EditCustomer from "./components/AccountDetails/CustomerSummary/EditCustomer/EditCustomer";
import EditMailerDetails from "./components/AccountDetails/EditMailerDetails/EditMailerDetails";
import EditMailerSummary from "./components/AccountDetails/MailerSummary/EditMailerSummary/EditMailerSummary";
import AddIntegrator from "./components/Integrators/AddIntegrator/AddIntegrator";
import AddChannel from "./components/AccountDetails/AdvancedConfig/ConfigLists/ActiveChannels/AddChannel/AddChannel";
import AddAccount from "./components/Accounts/AddAccount/AddAccount";
import DeleteChannel from "./components/AccountDetails/AdvancedConfig/ConfigLists/ActiveChannels/DeleteChannel/DeleteChannel";
import EditChannels from "./components/AccountDetails/AdvancedConfig/ConfigLists/ActiveChannels/EditChannels/EditChannels";
import EditContactFields from "./components/AccountDetails/AdvancedConfig/ConfigLists/ContactFields/EditContactFields/EditContactFields";

const ChangePassword = React.lazy(() => import('./components/ChangePassword/ChangePassword'));

const doAuthChecks = (setAuthChecksComplete, dispatch) => {
    axios.get("/api/login-session", {headers: {"Cache-Control": "no-store"}})
        .then(({data: {success, user}}) => {
            if (success) {
                dispatch({
                    type: SET_USER,
                    user: user
                })
            }
        }).finally(() => setAuthChecksComplete(true));
};

const RestrictedRoutes = ({isAuthenticated}) => {
    if (!isAuthenticated) return <Redirect to="/login"/>;
    return (
        <Switch>

            <Route exact path="/users">
                <PayreqUsers/>
            </Route>
            <Route path="/users/:id/events" children={<UserEvents/>}/>
            <Route path="/users/:id" children={<SpecificPayreqUser/>}/>

            <Route exact path="/accounts">
                <PayreqAccounts/>
            </Route>
            <Route path="/accounts/add" children={<AddAccount/>}/>
            <Route path="/accounts/:id/credits" children={<MailerCredits/>}/>
            <Route path="/accounts/:id/add-contact" children={<AddContact/>}/>
            <Route path="/accounts/:id/edit-customer" children={<EditCustomer/>}/>
            <Route path="/accounts/:id/edit-mailer-details" children={<EditMailerDetails/>}/>
            <Route path="/accounts/:id/config-lists/channel-edit" children={<EditChannels/>}/>
            <Route path="/accounts/:id/config-lists/contacts-edit" children={<EditContactFields/>}/>
            <Route path="/accounts/:id/config-lists/add" children={<AddChannel/>}/>
            <Route path="/accounts/:id/config-lists/delete" children={<DeleteChannel/>}/>
            <Route path="/accounts/:id/config-lists" children={<ConfigLists/>}/>
            <Route path="/accounts/:id/edit-mailer-summary" children={<EditMailerSummary/>}/>
            <Route path="/accounts/:id/template/:templateId" children={<MailTemplatesDetails/>}/>
            <Route path="/accounts/:id" children={<AccountDetails/>}/>

            <Route exact path="/approval-requests">
                <ApprovalRequests/>
            </Route>
            <Route path="/approval-requests/:id" children={<RequestDetails/>}/>

            <Route exact path="/integrators">
                <Integrators/>
            </Route>
            <Route path="/integrators/add" children={<AddIntegrator/>}/>
            <Route path="/integrators/:id" children={<IntegratorDetails/>}/>

            <Route exact path="/change-password">
                <Suspense fallback={<Loading/>}>
                    <ChangePassword/>
                </Suspense>
            </Route>
            <Route exact path="/">
                <Dashboard/>
            </Route>
        </Switch>
    );
}
const UnrestrictedRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login">
                <Login/>
            </Route>
        </Switch>
    );
};

const PayreqRouter = () => {
    const [{user}, dispatch] = useAppState();
    const [csrfProccessed, setCsrfProccessed] = useState(false);
    const [authChecksComplete, setAuthChecksComplete] = useState(false);

    useEffect(() => doAuthChecks(setAuthChecksComplete, dispatch),
        [setAuthChecksComplete, dispatch]);
    useEffect(() => getCsrfToken(setCsrfProccessed), [setCsrfProccessed]);

    if (!authChecksComplete || !csrfProccessed) return <Loading/>
    return (
        <Router>
            <PayreqNav isAuthenticated={user.isAuthenticated}/>
                <Container style={{
                    marginTop: "9rem",
                    marginBottom: "2rem",
                    minHeight: "80vh"
                    }} fluid="md">
                    <UnrestrictedRoutes/>
                    <RestrictedRoutes isAuthenticated={user.isAuthenticated}/>
                </Container>
        </Router>
    );
};

export default PayreqRouter;